import { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { testResult } from '../../testPayload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { apexDetails } from '../../game-details/apex';
import { valorantDetails } from '../../game-details/valorant';
import { fortniteDetails } from '../../game-details/fortnite';

import '../../App.css';

const channelUrl = 'https://www.youtube.com/channel/';
const url = 'https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=24&q=';
const useTestData = true;

function Home() {
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [gameDetails, setGameDetails] = useState();
  const [displayTerm, setDisplayterm] = useState('');

  const searchTermChanged = (event) => {
    setSearchTerm(event.target.value);
  }

  const resetSearch = () => {
    setDisplayterm('');
    setResults([]);
    setGameDetails();
  }

  const getFormattedResults = (result) => {
    const formattedResults = [];

    result.items.forEach(item => {
      // console.log("i am item", item)
      const formattedItem = {
        videoId: item.id.videoId,
        channelId: item.snippet.channelId,
        channelName: item.snippet.channelTitle,
        videoTitle: item.snippet.title,
        thumbnail: item.snippet.thumbnails.medium
      }
      formattedResults.push(formattedItem);
    })

    return formattedResults;
  }

  const search = (quickSearch) => {
    resetSearch()
    const game = searchTerm || quickSearch;

    if (useTestData) {
      setTimeout(() => {
        switch (game.toLowerCase()) {
          case 'apex legends':
            apexDetails.channelCount = testResult.pageInfo.totalResults;
            setGameDetails(apexDetails);
            break;
          case 'valorant':
            valorantDetails.channelCount = testResult.pageInfo.totalResults;
            setGameDetails(valorantDetails);
            break;
          case 'fortnite':
            fortniteDetails.channelCount = testResult.pageInfo.totalResults;
            setGameDetails(fortniteDetails);
            break;
          default:
            setDisplayterm(searchTerm);
        }
        setResults(getFormattedResults(testResult))
      }, 2000)
    } else {
      const API_KEY = process.env.REACT_APP_YouTube_API_KEY
      const fullUrl = `${url}${searchTerm}&key=${API_KEY}&eventType=live&type=video&order=viewCount`;

      fetch(fullUrl)
      .then(res => res.json())
      .then(
        (result) => {
          switch (game.toLowerCase()) {
            case 'apex legends':
              apexDetails.channelCount = testResult.pageInfo.totalResults;
              setGameDetails(apexDetails);
              break;
            case 'valorant':
              valorantDetails.channelCount = testResult.pageInfo.totalResults;
              setGameDetails(valorantDetails);
              break;
            case 'fortnite':
              fortniteDetails.channelCount = testResult.pageInfo.totalResults;
              setGameDetails(fortniteDetails);
              break;
            default:
              setDisplayterm(searchTerm);
          }
          setResults(getFormattedResults(result))
        },
        (error) => {
          console.error(error)
        }
      )
    }
    setSearchTerm('');
  }

  const quickSearch = (gameName) => {
    setSearchTerm(gameName);
    search(gameName);
  }

  return (
    <div className="App">
      <div className='search-container'>
        <div className='games-container'>
          <div className='quick-search-game-container' onClick={() => quickSearch('apex legends')}>
            <img src='https://static-cdn.jtvnw.net/ttv-boxart/511224-144x192.jpg' className='quick-search-game' />
          </div>
          <div className='quick-search-game-container' onClick={() => quickSearch('valorant')}>
            <img src='https://static-cdn.jtvnw.net/ttv-boxart/516575-144x192.jpg' className='quick-search-game' />
          </div>
          <div className='quick-search-game-container' onClick={() => quickSearch('fortnite')}>
            <img src='https://static-cdn.jtvnw.net/ttv-boxart/33214-144x192.jpg' className='quick-search-game' />
          </div>
        </div>
        <div className='search-controls-container'>
          <div className='search-controls'>
            <TextField id="outlined-basic" label="Search" variant="outlined" onChange={searchTermChanged} value={searchTerm} className='search-field' />
            <Button variant="outlined" onClick={search} className='search-button'>
              <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            </Button>
          </div>
        </div>
      </div>
      {displayTerm && <p className='custom-search'>showing results for "{displayTerm}"</p>}
      {gameDetails &&
        <div>
          <div className='game-logo-container'>
            <div><img src={gameDetails.imgUrl} /></div>
            <div className='game-details'>
              <p className='game-details-name'>{gameDetails.name}</p>
              <p className='game-details-channel-count'>{gameDetails.channelCount} live channels</p>
              <p className='game-details-description'>{gameDetails.description}</p>
            </div>
          </div>
        </div>
      }
      {
        results.length &&
          <div className='sorted-by'>
            Sorted by Viewers (High to Low)
          </div>
      }
      <div className='results'>
        {
          results.map((result, index) => (
              <div key={index} className='result'>
                <Link to={`/watch/${result.videoId}`} className='video-link'>
                  <div className='thumbnail-container'>
                    <img src={result.thumbnail.url} className='thumbnail' />
                  </div>
                  <p className='title'>{result.videoTitle}</p>
                </Link>
                <a href={`${channelUrl}${result.channelId}`} target='_blank' className='video-link'>
                  <p className='channelName'>{result.channelName}</p>
                </a>
              </div>
            )
          )
        }
      </div>
      <div className='made-by'>
        <FontAwesomeIcon icon={faGamepad} className="made-by-icon" /> made by <a href="https://twitter.com/tyypos_" target="_blank" className='made-by-link'>Tyypos</a>
      </div>
    </div>
  );
}

export default Home;
