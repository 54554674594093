export const testResult = {
    "kind": "youtube#searchListResponse",
    "etag": "8CpLU9lltxAGEgAQlrfmsx8kyvM",
    "nextPageToken": "CBkQAA",
    "regionCode": "US",
    "pageInfo": {
      "totalResults": 1220,
      "resultsPerPage": 25
    },
    "items": [
      {
        "kind": "youtube#searchResult",
        "etag": "Pa35rCeB8uPvhkyZzYIJeNOqrjU",
        "id": {
          "kind": "youtube#video",
          "videoId": "ipOqU5IsSWo"
        },
        "snippet": {
          "publishedAt": "2022-09-22T13:20:58Z",
          "channelId": "UC82hr_TycIBi_BvEnWVoGIw",
          "title": "Modern Warfare 2 Beta Gameplay - TheBrokenMachine&#39;s Chillstream",
          "description": "Call of Duty: Modern Warfare II Beta Multiplayer Gameplay Warzone 2 Gameplay youtu.be/ZMoQL7j_GtM #ModernWarfare2 If you ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/ipOqU5IsSWo/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/ipOqU5IsSWo/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/ipOqU5IsSWo/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "TheBrokenMachine",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T13:20:58Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "FUyBfDUbo8d1AQ0J4Qx55Ge4zu8",
        "id": {
          "kind": "youtube#video",
          "videoId": "tj0fPtxB7AE"
        },
        "snippet": {
          "publishedAt": "2022-09-22T13:53:28Z",
          "channelId": "UC8PkMtz5Ah3ZiIWna02HVyQ",
          "title": "💀SAIU WARZONE MOBILE DA NETEASE! INACREDITAVEL! PROJECT BLOOD STRIKE!",
          "description": "WARZONE #BLOODSTRIKE Meu instagram: https://www.instagram.com/el_caverinha/ Twitter: https://twitter.com/ElCaverinha ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/tj0fPtxB7AE/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/tj0fPtxB7AE/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/tj0fPtxB7AE/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "El Caverinha",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T13:53:28Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "EEKVQF9Et3J2xqVXfXBSADiOBL8",
        "id": {
          "kind": "youtube#video",
          "videoId": "mOle5uxfsE4"
        },
        "snippet": {
          "publishedAt": "2022-09-22T14:22:40Z",
          "channelId": "UClgYUdjvp4SqjTK2dC8O2hw",
          "title": "WARZONE: | 3rd All-Time In Big Map Wins | (6,101+ Wins)",
          "description": "callofduty #warzonelive #battleroyale #live #stream #battleroyale #rebirth #fortuneskeep #resurgence #warzone Stream ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/mOle5uxfsE4/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/mOle5uxfsE4/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/mOle5uxfsE4/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "SoCaLove",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T14:22:40Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "VaS28kTiIhhjHJbQ0jvx07EHT-E",
        "id": {
          "kind": "youtube#video",
          "videoId": "mc1W2GhxT-0"
        },
        "snippet": {
          "publishedAt": "2022-09-22T14:35:20Z",
          "channelId": "UCTVlDAKumOZq0-O5Pmg5a5w",
          "title": "Time to Practice for Warzone 2.0",
          "description": "Sallyisadog tries to stream on YouTube for the first time.",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/mc1W2GhxT-0/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/mc1W2GhxT-0/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/mc1W2GhxT-0/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Sally Is a Dog",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T14:35:20Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "Bd_-mP-YmOZSypw9OLHgAhH9Tjc",
        "id": {
          "kind": "youtube#video",
          "videoId": "jIyN8WIvBMo"
        },
        "snippet": {
          "publishedAt": "2022-01-24T01:07:19Z",
          "channelId": "UCi_VsjkIy8vPMQnRMhPu9OA",
          "title": "[🔴24/7 STREAM] Warzone high score games, loadouts, guides... | Call of Duty Warzone",
          "description": "Get free $200 of COD Points: http://FreeCodPoints.nikolarn.com BE SURE TO THE BROADCAST AND BE SURE TO ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/jIyN8WIvBMo/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/jIyN8WIvBMo/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/jIyN8WIvBMo/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Nikolarn",
          "liveBroadcastContent": "live",
          "publishTime": "2022-01-24T01:07:19Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "n5kVt5JWoAaUQOsueeK1WbUl3O4",
        "id": {
          "kind": "youtube#video",
          "videoId": "YMwrjU7O0Fo"
        },
        "snippet": {
          "publishedAt": "2022-09-19T20:39:29Z",
          "channelId": "UCStx7WUSQFVumYkZ3NxdkWg",
          "title": "CALL OF DUTY: WARZONE MOBILE WORLD FIRST REVEAL! #callofduty #warzone #ShiverGamerz #battle",
          "description": "CALL OF DUTY: WARZONE MOBILE WORLD FIRST REVEAL! #callofduty #warzone #ShiverGamerz #battle On this channel I do ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/YMwrjU7O0Fo/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/YMwrjU7O0Fo/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/YMwrjU7O0Fo/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Shiver Gamerz",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-19T20:39:29Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "DxW1B4lsfQhCahX9p4_0QBOLI7M",
        "id": {
          "kind": "youtube#video",
          "videoId": "xG-NVyp2UCQ"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:03:29Z",
          "channelId": "UC-gqubY7nb5vtsogxKJE79w",
          "title": "БЕТА-ТЕСТ МВ2 СЕГОДНЯ В 20-00 ДЛЯ ПРЕДЗАКАЗОВ! WARZONE X VANGUARD СТРИМ ОНЛАЙН!",
          "description": "СООБЩЕНИЕ НА ЭКРАН ОТ 25 РУБЛЕЙ: http://www.donationalerts.com/c/sorryhanzofake Вступайте в мою группу ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/xG-NVyp2UCQ/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/xG-NVyp2UCQ/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/xG-NVyp2UCQ/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Kek the King",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:03:29Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "o50-Vb5KW6fNwLeuCNczObmEQa4",
        "id": {
          "kind": "youtube#video",
          "videoId": "ODDWCFsWYjU"
        },
        "snippet": {
          "publishedAt": "2022-09-22T12:47:29Z",
          "channelId": "UChTPuIWbwkiSFsTmhO_5-Aw",
          "title": "MW2 BETA LIVE GAMEPLAY for WEEKEND 2 for PS4, PS5, XBOX, AND PC TODAY! Modern Warfare 2 &amp; Warzone 2!",
          "description": "MW2 BETA LIVE GAMEPLAY for WEEKEND 2 for PS4, PS5, XBOX, AND PC TODAY! Modern Warfare 2 & Warzone 2! #mw2 ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/ODDWCFsWYjU/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/ODDWCFsWYjU/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/ODDWCFsWYjU/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Kdubz",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T12:47:29Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "tr0oRqsnj88N6Y_YOEbQjvWFqWA",
        "id": {
          "kind": "youtube#video",
          "videoId": "-9ULZu4KNeY"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:42:43Z",
          "channelId": "UCW-IbRKU_lhCPzRmab-riIQ",
          "title": "Warzone Gelene Kadar En İyisi Bu | Project: BloodStrike - Canlı Yayın",
          "description": "Download/İndir: https://wp.me/p96ctg-2X7.",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/-9ULZu4KNeY/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/-9ULZu4KNeY/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/-9ULZu4KNeY/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Ersin Çakı",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:42:43Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "rTRpYXEP2qSrqYGTJLVWbl9E4Vk",
        "id": {
          "kind": "youtube#video",
          "videoId": "IZnMf62D2bY"
        },
        "snippet": {
          "publishedAt": "2022-09-22T03:36:18Z",
          "channelId": "UCS9uSgMDZUTzvP2fYyLa42A",
          "title": "🔴 LIVE - | WARZONE 2 WAITING ROOM AND NINJA DEMON MOVEMENT CLASSES 🔥 | MW2 BETA TODAY (PC)",
          "description": "LIVE - | WARZONE 2 WAITING ROOM AND NINJA DEMON MOVEMENT CLASSES | MW2 BETA TODAY (PC) Become a ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/IZnMf62D2bY/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/IZnMf62D2bY/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/IZnMf62D2bY/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Von VS Everybody",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T03:36:18Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "qMiUjExGQrd6am7RYyd_nYy7EXA",
        "id": {
          "kind": "youtube#video",
          "videoId": "292Ug6cLOGA"
        },
        "snippet": {
          "publishedAt": "2022-09-22T13:14:32Z",
          "channelId": "UCYTlCHkmylOGuh4kUTb9Yiw",
          "title": "🔴 JOGANDO o WARZONE MOBILE LITE! (PROJECT BLOODSTRIKE)",
          "description": "Meu Tiktok: https://www.tiktok.com/@7heph Canal Secundário: ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/292Ug6cLOGA/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/292Ug6cLOGA/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/292Ug6cLOGA/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "ThepH",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T13:14:32Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "4aP-AF1hHux4a9KTXpo8tnVFVNU",
        "id": {
          "kind": "youtube#video",
          "videoId": "30ax44FvD1E"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:51:10Z",
          "channelId": "UCN7pYLFyjYTz5SRpPdqKUkw",
          "title": "*LIVE*#1 KILLA BUNNY IN WARZONE MW2 GAMEPLAY | REBIRTH ISLAND FORTUNES KEEP |",
          "description": "Support the stream: https://streamlabs.com/lboogie_time ~~ MAKE SURE TO LIKE SHARE AND DEF SUBSCRIBE ~~ ___ALL ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/30ax44FvD1E/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/30ax44FvD1E/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/30ax44FvD1E/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "LBoogie_Time",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:51:10Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "NvlHF4LhJ00CIJJLpvXZo7Ez8Go",
        "id": {
          "kind": "youtube#video",
          "videoId": "840t-Ab-A-s"
        },
        "snippet": {
          "publishedAt": "2022-09-22T16:30:55Z",
          "channelId": "UCDI3JFn4bENf5E0_lnYPjvA",
          "title": "🔴 5 KD - WARZONE 2.0 MAP GAMEPLAY (CONSOLE DEMON) 🇨🇦 🇲🇽 🇩🇪 🇺🇸",
          "description": "CONSOLE DEMON and 80 FOV KING with 5 K/D Plays Rebirth and FORTUNES KEEP on Controller in WARZONE SEASON 5 ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/840t-Ab-A-s/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/840t-Ab-A-s/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/840t-Ab-A-s/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Band of Bros",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T16:30:55Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "6GCa5H2ooudACX_uqvsXrkpMN8U",
        "id": {
          "kind": "youtube#video",
          "videoId": "4aBzkj77S9M"
        },
        "snippet": {
          "publishedAt": "2022-09-22T10:18:20Z",
          "channelId": "UC5cZDxpaNckj9eG0dRF7YOg",
          "title": "🔴 Modern Warfare 2 Early Access Beta Gameplay Live | Warzone Live Rebirth Island |",
          "description": "Hands on the MWII Beta Multiplayer tonight. Warzone 2 release date has been confirmed (16 Nov). If you guys are interested in ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/4aBzkj77S9M/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/4aBzkj77S9M/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/4aBzkj77S9M/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "BRIGZARD",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T10:18:20Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "Vlm3NejFwhSar-x4__81fZvSAcU",
        "id": {
          "kind": "youtube#video",
          "videoId": "CeGpPPSEWTM"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:13:10Z",
          "channelId": "UCZj8gWK__JOJWhrB3pKRUMg",
          "title": "HIGH KILLS &amp; TOURNAMENTS -  CoD Warzone Rebirth Island Live Stream - Xbox Controller Demon",
          "description": "warzone #warzonelive #rebirthisland #codwarzone #rebirthreturns #vanguard #mw2 #warzone2 #yt #warzone #rebirthisland ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/CeGpPPSEWTM/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/CeGpPPSEWTM/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/CeGpPPSEWTM/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "PesoBeenPaid",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:13:10Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "mpGZesfEU9CGoBc1I5vnLwgcGPw",
        "id": {
          "kind": "youtube#video",
          "videoId": "vqzvrrNexDA"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:18:11Z",
          "channelId": "UCs-DrkhwWmabsyw4Tnw2Mng",
          "title": "🔴WARZONE MOBILE LITE LANÇOU KKKKKKKKKKKKKKKKKKKKKKKKKKK",
          "description": "warzone #warzonemobile #MATHSS #corinthians NÃO CLICA AQUI:https://bit.ly/3yaxn7B Faça um donate me ajuda a manter o ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/vqzvrrNexDA/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/vqzvrrNexDA/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/vqzvrrNexDA/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "MATHSS ",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:18:11Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "3I647PDx02EwPddbFsZfp9FvRRw",
        "id": {
          "kind": "youtube#video",
          "videoId": "hRaTZZAuGQg"
        },
        "snippet": {
          "publishedAt": "2022-09-22T13:44:58Z",
          "channelId": "UCoKDFQxVlhsuqFwHV4hTCsw",
          "title": "WARZONE MOBILE LITE? (Bloodstrike Gameplay)",
          "description": "Support the stream: https://streamlabs.com/intenseglitcherhd/ 2nd CHANNEL - https://www.youtube.com/c/ExtraIntenseXYZ My ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/hRaTZZAuGQg/default.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/hRaTZZAuGQg/mqdefault.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/hRaTZZAuGQg/hqdefault.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "IntenseReality",
          "liveBroadcastContent": "none",
          "publishTime": "2022-09-22T13:44:58Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "x9fV6TWBmPL6hu_e7IiFKLFfMhE",
        "id": {
          "kind": "youtube#video",
          "videoId": "lHHNx_8KeCc"
        },
        "snippet": {
          "publishedAt": "2022-09-22T12:33:09Z",
          "channelId": "UC9LzOWjsaMtR9kV4XjL2s1g",
          "title": "WARZONE配信❗@3遊んでくれる方😉 コメントお待ちしてます😊",
          "description": "なかつーと呼んで下さい   コメント一言目は「おつなかつー」でお願いしますw CODをcwから始めました❗️ フレンド作りで ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/lHHNx_8KeCc/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/lHHNx_8KeCc/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/lHHNx_8KeCc/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "なかつーゲームクラブ🤞",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T12:33:09Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "OdcvZgjR8qC43G2jaKXuY7AujfQ",
        "id": {
          "kind": "youtube#video",
          "videoId": "rwddyo9rCt8"
        },
        "snippet": {
          "publishedAt": "2022-04-15T12:18:40Z",
          "channelId": "UCntNss1UiP9oDe1nWZDM5mg",
          "title": "🔴 Live  WARZONE  after a really LONG TIME BACK AT WARZONE",
          "description": "Warzone Call of Duty: Warzone is a free-to-play battle royale video game released on March 10, 2020, for PlayStation 4, Xbox ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/rwddyo9rCt8/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/rwddyo9rCt8/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/rwddyo9rCt8/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "GAMIFIED INDIAN",
          "liveBroadcastContent": "live",
          "publishTime": "2022-04-15T12:18:40Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "8HQ5kL4K_wP8BSZRZCfRjm2clmc",
        "id": {
          "kind": "youtube#video",
          "videoId": "-qdK03E1NrM"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:30:53Z",
          "channelId": "UCZT5NlfmqtEMNfYW3KuMblA",
          "title": "🔴Live🔴 Warzone Max Sense Demon + Mw2 Beta Grind | High Kill Wins",
          "description": "modernwarfare2 #warzone2 #CallOfDuty #Vanguardlive #VanguardGameplay #CallOfDutyVanguardLive #warzonelive ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/-qdK03E1NrM/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/-qdK03E1NrM/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/-qdK03E1NrM/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "AaronTheGoat",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:30:53Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "bFxrn0BEmikBmrFXPggyoCfO-EI",
        "id": {
          "kind": "youtube#video",
          "videoId": "ok8cPevmIOU"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:50:22Z",
          "channelId": "UCojHr3npf_rl5nBMKGz9vQA",
          "title": "LIVE | COD WARZONE PACIFIC &amp; MODERN WARFARE 2 OPEN BETA LATER | ROAD TO 400 SUBS | TAMIL",
          "description": "Sub if you like Follow me on Twitch : https://www.twitch.tv/reptilianvampire TAGS: #cod #warzone #apexlegends #tamilgaming ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/ok8cPevmIOU/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/ok8cPevmIOU/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/ok8cPevmIOU/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Reptilian Vampire",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:50:22Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "mMHClNVed-Ec5krQ-rJz0E3l37w",
        "id": {
          "kind": "youtube#video",
          "videoId": "8b3Uq7D8XY0"
        },
        "snippet": {
          "publishedAt": "2022-09-22T14:05:27Z",
          "channelId": "UCTO5VoqkLZvPI7mgiFmkwxQ",
          "title": "Rebirth LIVE - THRIVING THURSDAY  !  :) - 2.48 kd | 74K Kills | 1675+W&#39;s #cod #warzone",
          "description": "WackyDOINKER MEMBERSHIP: https://www.youtube.com/channel/UCTO5VoqkLZvSEXYPI7mgiFmkwxQ/join Support the ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/8b3Uq7D8XY0/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/8b3Uq7D8XY0/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/8b3Uq7D8XY0/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "vDoinks",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T14:05:27Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "6nVlt1jBxeTxhxgxjF6mknQYw_M",
        "id": {
          "kind": "youtube#video",
          "videoId": "DJM9-zJhZtg"
        },
        "snippet": {
          "publishedAt": "2022-09-22T09:39:34Z",
          "channelId": "UCy4DtorKb2WQdD036H81FZg",
          "title": "Modern Warfare II Beta",
          "description": "Call of Duty Warzone Pacific Caldera LIVE Ways to support me: UPI: ladyredhood@ybl PayPal: ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/DJM9-zJhZtg/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/DJM9-zJhZtg/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/DJM9-zJhZtg/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "Lady Redhood",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T09:39:34Z"
        }
      },
      {
        "kind": "youtube#searchResult",
        "etag": "IMfvWHQrR7aevFgkALGxWfXkhRk",
        "id": {
          "kind": "youtube#video",
          "videoId": "vHJiq8aiosU"
        },
        "snippet": {
          "publishedAt": "2022-09-22T15:40:40Z",
          "channelId": "UCwPgPrfgg3kqh9TMqnSPjWw",
          "title": "Last Day For Warzone. Let The Pain Begin.....  Stop By And Say HELLO!!!",
          "description": "BE NICE TO EVERYONE IN CHAT OR YOU WILL BE TIMED OUT. THANKS Support the stream: ...",
          "thumbnails": {
            "default": {
              "url": "https://i.ytimg.com/vi/vHJiq8aiosU/default_live.jpg",
              "width": 120,
              "height": 90
            },
            "medium": {
              "url": "https://i.ytimg.com/vi/vHJiq8aiosU/mqdefault_live.jpg",
              "width": 320,
              "height": 180
            },
            "high": {
              "url": "https://i.ytimg.com/vi/vHJiq8aiosU/hqdefault_live.jpg",
              "width": 480,
              "height": 360
            }
          },
          "channelTitle": "HMUREDLINE ",
          "liveBroadcastContent": "live",
          "publishTime": "2022-09-22T15:40:40Z"
        }
      }
    ]
  }
  