import { useParams } from "react-router-dom";

function Watch() {
    const { id } = useParams(); 

    return (
        <div>
            <iframe className='iframe' src={`https://www.youtube.com/embed/${id}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
}

export default Watch;