import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home, Watch } from "./components";

import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/watch/:id' exact element={<Watch />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
